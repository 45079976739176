import { LoadPanel } from "devextreme-react"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"
import _ from "underscore"
import {
  addLevel,
  createUser,
  getAllOrganizations,
  getUsers,
  updateUser,
  getAllOffices
} from "../../../services"
import { formatToIsoUtc } from "../../../Utils/formatToIsoUtc"
import PageTitle from "./../../../Layout/AppMain/PageTitle"
import HCCreate from "./Main/HCCreate"
import { cleanObject } from "./Main/Utils/CleanObject"
import clone from "./Main/Utils/Clone"
import { parseErrors, showToastError } from "./Main/Utils/ErrorHandling"
import { FormValidator } from "./Main/Utils/FormValidator"
import { parseUserDates } from "./Main/Utils/ParseUserDates"
import "./Styles/edit.css"
import "./Styles/searchBoxPadding.css"

export default function HeadcountEdit({ history }) {
  const [newUser, setNewUser] = useState({
    currentLevel: {}
  })
  const [canCreateNewUser, setCanCreateNewUser] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const [errors, setErrors] = useState([])
  const formRef = useRef(null)
  const validator = new FormValidator()

  useEffect(() => {
    setErrors([])
  }, [newUser, enabled])

  useEffect(() => {
    formRef?.current?.instance?.validate()
  }, [errors])

  const config = useSelector((state) => state.Auth.config)
  const [organizations, setOrganizations] = useState([])
  const [levels, setLevels] = useState([])
  const [bipRoles, setBipRoles] = useState([])
  const [offices, setOffices] = useState([])
  const [competences, setCompetences] = useState([])
  const [contractTypes, setContractTypes] = useState([])
  const [adbS, setAdbS] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [globalCompetences, setGlobalCompetences] = useState([])
  const [circleValues, setCircleValues] = useState([])
  const [legalEntityValues, setLegalEntityValues] = useState([])
  const [genderValues, setGenderValues] = useState([])
  const [userNames, setUserNames] = useState([])

  useEffect(() => {
    if (config) {
      setLevels(_.sortBy(config.levels, "name"))
      setBipRoles(config.bipRoles.sort())
      setCompetences(config.competences.sort())
      setContractTypes(config.contractType.sort())
      setAdbS(config.adbS.sort())
      setSuppliers(config.suppliers.sort())
      setGlobalCompetences(config.globalCompetences.sort())
      setCircleValues(config.circleValues.sort())
      setGenderValues(config.genderValues)
      setLegalEntityValues(config.legalEntities.sort())
    }
  }, [config])

  useEffect(() => {
    getAllOffices().then(({ data }) => {
      setOffices(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          code: j.code
        }))
      )
    })
  }, [])

  useEffect(() => {
    getUsers().then(({ data }) => {
      setUserNames(
        data
          .sort(function(a, b) {
            var A = a.firstName.toUpperCase()
            var B = b.firstName.toUpperCase()
            return A < B ? -1 : A > B ? 1 : 0
          })
          .map((user) => ({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`
          }))
      )
    })
  }, [])

  useEffect(() => {
    getAllOrganizations().then(({ data }) => {
      setOrganizations(
        data.map((j) => ({
          id: j._id,
          name: j.name,
          owner: j.owner,
          isDeleted: j.isDeleted
        }))
      )
    })
  }, [])

  useEffect(() => {
    setCanCreateNewUser(
      newUser.firstName &&
        newUser.lastName &&
        (newUser.isTeam || newUser.email) &&
        newUser.extId &&
        newUser.currentLevel &&
        newUser.currentLevel.name &&
        newUser.currentLevel.code &&
        newUser.currentLevel.cost &&
        newUser.currentLevel.startDate &&
        newUser.firstName !== "" &&
        newUser.lastName !== "" &&
        (newUser.isTeam || newUser.email !== null) &&
        newUser.extId !== "" &&
        newUser.currentLevel.name !== "" &&
        newUser.currentLevel.code !== "" &&
        newUser.currentLevel.cost !== "" &&
        newUser.currentLevel.startDate !== ""
    )
  }, [newUser])

  const onCancel = () => {
    history.push("/admin/headcount")
  }

  const getLevelByCode = (code) => {
    return levels.find((l) => {
      return l.code === code
    })
  }

  const getNewUserData = () => {
    let data = clone(newUser)
    data.extId = data.extId.toUpperCase()
    data.joiningDate = formatToIsoUtc(data.joiningDate)
    data.currentLevel.startDate = formatToIsoUtc(data.currentLevel.startDate)
    if (data.contractEnd) {
      data.contractEnd = formatToIsoUtc(data.contractEnd)
    }
    if (data.orderEnd) {
      data.orderEnd = formatToIsoUtc(data.orderEnd)
    }
    data = cleanObject(data)
    delete data.currentLevel
    return data
  }

  const getNewLevelData = () => {
    let currentLevel = null
    if (
      newUser.currentLevel &&
      newUser.currentLevel.cost &&
      newUser.currentLevel.startDate
    ) {
      currentLevel = { ...newUser.currentLevel }
      currentLevel.cost = currentLevel.cost / 8
      currentLevel.startDate = formatToIsoUtc(currentLevel.startDate)
    }
    return currentLevel
  }

  const onSave = async () => {
    setErrors([])
    setSpinner(true)
    await new Promise((r) => setTimeout(r))

    if (!canCreateNewUser) {
      return
    }

    try {
      let addUserResponse = await createUser(getNewUserData())
      try {
        let addLevelResponse = await addLevel(
          addUserResponse.data._id,
          getNewLevelData()
        )
        const userAdded = parseUserDates({
          ...addUserResponse.data,
          currentLevel: addLevelResponse.data
        })

        if (!enabled) {
          history.push(`edit/${userAdded._id}`)
          return
        }

        try {
          let enabledUpdateUser = {
            ...getNewUserData(),
            _id: userAdded._id,
            enabled: enabled
          }
          let updateUserResponse = await updateUser(enabledUpdateUser)
          history.push(`edit/${updateUserResponse.data._id}`)
        } catch (err) {
          setSpinner(false)
          handleError(err)
          history.push(`edit/${addUserResponse.data._id}`)
        }
      } catch (err) {
        setSpinner(false)
        handleError(err)
        history.push(`edit/${addUserResponse.data._id}`)
      }
    } catch (err) {
      setSpinner(false)
      handleError(err)
    }
  }

  const handleError = (err) => {
    if (!err.response) {
      showToastError(formatMessage("hc-create-user-generic-error"))
      console.error(err)
      return
    }

    showToastError(err.response)
    const parsedErrors = parseErrors(err.response)
    setErrors(parsedErrors)
  }

  function renderButtons() {
    return (
      <div>
        <Button
          outline
          className="mr-2"
          color="primary"
          d="button"
          width={100}
          height={40}
          onClick={onCancel}
        >
          <span className="dx-button-text">
            {formatMessage("hc-button-cancel").toUpperCase()}
          </span>
        </Button>
        <Button
          className="mr-3"
          color="primary"
          width={100}
          height={40}
          onClick={onSave}
          disabled={!canCreateNewUser}
        >
          <span className="dx-button-text">
            {formatMessage("hc-button-save").toUpperCase()}
          </span>
        </Button>
      </div>
    )
  }

  return (
    <Fragment>
      <PageTitle
        heading={formatMessage("hc-create-title")}
        icon="pe-7s-graph text-success"
        actions={renderButtons()}
      ></PageTitle>
      <LoadPanel
        visible={spinner}
        indicatorSrc={`data:image/svg+xml;utf8,${require("../../../assets/rolling.svg")}`}
      />
      <HCCreate
        formRef={formRef}
        errors={errors}
        validator={validator}
        spinner={spinner}
        user={newUser}
        setUser={setNewUser}
        enabled={enabled}
        setEnabled={setEnabled}
        organizations={organizations}
        levels={levels}
        bipRoles={bipRoles}
        offices={offices}
        competences={competences}
        contractTypes={contractTypes}
        adbS={adbS}
        suppliers={suppliers}
        globalCompetences={globalCompetences}
        circleValues={circleValues}
        legalEntityValues={legalEntityValues}
        genderValues={genderValues}
        userNames={userNames}
        getLevelByCode={getLevelByCode}
      />
    </Fragment>
  )
}
