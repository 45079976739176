import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getAllWbs, searchWbs } from "../../../../services"
import { parseWbsData } from "../utils/parseWbsData"

export function useJobAndWbsData() {
  const localStorageKey = "job-and-wbs-table-filters-and-sorting"

  const queryClient = useQueryClient()

  // PAGINATION //
  const [currentPage, setCurrentPage] = useState(
    parseInt(JSON.parse(localStorage.getItem(localStorageKey))?.currentPage) || 0
  )
  const [queryWbsError, setQueryWbsError] = useState(false)
  const [lastPage, setLastPage] = useState(0)
  const [amountCurrentlyVisualizing, setAmountCurrentlyVisualizing] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const onPageChange = (newPage) => {
    setCurrentPage(newPage)
  }
  const resetCurrentPage = () => {
    setCurrentPage(0)
  }

  useEffect(() => {
    if (currentPage > lastPage && lastPage !== 0) {
      setCurrentPage(lastPage)
    }
  }, [lastPage, currentPage])

  function saveLocalStoragePagination() {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(localStorageKey)),
        currentPage,
      })
    )
  }

  // SORTING //
  const [orderBy, setOrderBy] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))?.orderBy || "code"
  ) //"code" or "type"

  const [sortingOrder, setSortingOrder] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))?.sortingOrder || "DESC"
  ) //"ASC" or "DESC"

  function saveLocalStorageSorting() {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(localStorageKey)),
        orderBy,
        sortingOrder,
        pinnedUntil,
      })
    )
  }

  // FILTERS //
  const getLocalStorageFilters = () => {
    const savedFilters = JSON.parse(localStorage.getItem(localStorageKey))
    return {
      fiscalYear: savedFilters?.fiscalYear || "",
      accountable: savedFilters?.accountable || "",
      jobOwner: savedFilters?.jobOwner || "",
      deliveryMananger: savedFilters?.deliveryMananger || "",
      startDate: savedFilters?.startDate || "",
      endDate: savedFilters?.endDate || "",
      term: savedFilters?.term || "",
      adb: savedFilters?.adb || "",
      isActive: savedFilters?.isActive || "",
      isFinal: savedFilters?.isFinal || "",
      isTempPlaced: savedFilters?.isTempPlaced || "",
      status: savedFilters?.status || "",
      type: savedFilters?.type || "",
    }
  }

  const [filters, setFilters] = useState(getLocalStorageFilters())
  const getFilterValue = (filterName) => {
    return filters[filterName]
  }
  const setFilterValue = (filterName, value) => {
    const newFilters = { ...filters }
    newFilters[filterName] = value
    setFilters(newFilters)
    resetCurrentPage()
  }

  function saveLocalStorageFilters() {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(localStorageKey)),
        ...filters,
      })
    )
  }

  // PINNING //
  const [pinnedUntil, setPinnedUntil] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))?.pinnedUntil || null
  )

  // OTHERS //
  const expand = true
  const enablePagination = true
  const includeDeputyAccountables = true
  const includeSupporters = true

  const resetAllFilters = () => {
    resetCurrentPage()
    setFilters({
      fiscalYear: "",
      accountable: "",
      jobOwner: "",
      deliveryMananger: "",
      startDate: undefined,
      endDate: null,
      term: "",
      adb: "",
      isActive: "",
      isTempPlaced: "",
      isFinal: "",
      status: "",
      type: "",
    })
    setOrderBy("code")
    setSortingOrder("DESC")
  }

  useEffect(() => {
    saveLocalStorageFilters()
    saveLocalStoragePagination()
    saveLocalStorageSorting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentPage, orderBy, sortingOrder, pinnedUntil])

  async function queryFunction() {
    queryClient.cancelQueries(["GET", "wbs"])
    const sorting = { orderBy, sortingOrder }
    return (
      (await (filters.term && filters.term !== ""))
        ? searchWbs(
            enablePagination,
            currentPage,
            pageSize,
            filters,
            sorting,
            includeDeputyAccountables,
            includeSupporters
          )
        : getAllWbs(
            expand,
            enablePagination,
            currentPage,
            pageSize,
            filters,
            sorting,
            includeDeputyAccountables,
            includeSupporters
          )
    )
      .then((res) => {
        setQueryWbsError(false)
        setLastPage(Math.trunc((res.data.totalCount - 1) / pageSize))
        setTotalCount(res.data.totalCount)
        setAmountCurrentlyVisualizing(res.data.data?.length || 0)
        return res.data.data.map((wbs) => parseWbsData(wbs))
      })
      .catch((error) => {
        setQueryWbsError(true)
        return []
      })
  }

  // QUERY CALL //
  const { isLoading, isError, isSuccess, data, error, isFetching, refetch } =
    useQuery(
      [
        "GET",
        "wbs",
        {
          expand,
          enablePagination,
          currentPage,
          pageSize,
          filters,
          orderBy,
          sortingOrder,
          includeDeputyAccountables,
          includeSupporters,
        },
      ],
      queryFunction,
      {
        onSuccess: (res) => {
          res?.forEach((wbs) => {
            queryClient.setQueryData(["GET", "wbs/", wbs._id], wbs)
            wbs?.fiscalYears?.forEach((year) => {
              queryClient.setQueryData(["GET", "year/", year._id], year)
              year?.jobs?.forEach((job) => {
                queryClient.setQueryData(["GET", "job/", job.shortCode], job)
              })
            })
          })
        },
      }
    )

  return {
    pageSize,
    setPageSize,
    currentPage,
    lastPage,
    totalCount,
    amountCurrentlyVisualizing,
    onPageChange,
    getFilterValue,
    setFilterValue,
    orderBy,
    setOrderBy: (value) => {
      resetCurrentPage()
      setOrderBy(value)
    },
    sortingOrder,
    setSortingOrder: (value) => {
      resetCurrentPage()
      setSortingOrder(value)
    },
    pinnedUntil,
    setPinnedUntil,
    resetAllFilters,
    refetch,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    queryWbsError,
  }
}
