import DropdownSelect from "./input/DropdownSelect"
import "./Filter.scss"
import { formatMessage } from "devextreme/localization"
import { useSelectJobFilters } from "../hooks/selectHooks/useSelectJobFilters.js"
import { Spinner } from "./Spinner"

export default function DeliveryManagerFilter({
  deliveryManagerFilter,
  setDeliveryManagerFilter,
  className,
}) {
  const { deliveryManagerUserOptions, isLoading } = useSelectJobFilters()
  return (
    <DropdownSelect
      disabled={isLoading}
      searchable={true}
      className={`${className} filter-select font-weight-bold`}
      innerLabel={
        <>
          {isLoading && <Spinner />}
          <span className="text-muted pr-1">{formatMessage("jw-delivery manager")}:</span>
        </>
      }
      value={deliveryManagerFilter || "all"}
      placeholder={formatMessage("jw-all")}
      options={deliveryManagerUserOptions}
      onSelected={(option) => {
        setDeliveryManagerFilter(option?.id || null)
      }}
    />
  )
}
