import axios from "axios"
import { apiService } from "./APIServices"

export const getJobs = (params = {}) => {
  return axios.get(apiService.job, { params })
}

export const getJobsThumb = ({
  filterByUserAccountable,
  filterByUserOwner,
} = {}) => {
  return axios.get(apiService.job + "/thumb", {
    params: {
      user_is_accountable: filterByUserAccountable,
      user_is_owner: filterByUserOwner,
    },
  })
}

export const getJob = (shortCode, includeDeputyAccountables, includeSupporters) => {
  return axios.get(`${apiService.job}/${shortCode}/detail`, {
    params: {
      includeDeputyAccountables: includeDeputyAccountables,
      includeSupporters: includeSupporters
    },
  })
}

export const createJob = async (job) => {
  return axios.post(apiService.job, job)
}

export const updateJob = async (job) => {
  return axios.put(apiService.job + `/${job._id}`, job)
}

export const deleteJob = (jobId) => {
  return axios.delete(apiService.job + `/${jobId}`)
}

export const getJobsSuggestions = () => {
  return axios.get(apiService.job + "/fields-suggestion")
}

export const getJobFilters = () => {
  return axios.get(apiService.job + "/filters")
}
