import "bootstrap/dist/css/bootstrap.min.css"
import { Tooltip } from "devextreme-react/tooltip"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useState } from "react"
import Tabs from "react-responsive-tabs"
import { withRouter } from "react-router"
import { Card, CardBody, Col, Form, Row } from "reactstrap"
import { getLevels } from "../../../../services"
import "./../../../../style/dx.material.bip-scheme.css"
import MultiSwitchComponent from "./Components/MultiSwitchComponent"
import SwitchToExternalOrInternalPopup from "./Components/SwitchToExternalOrInternalPopup"
import AdditionalInfoForm from "./Edit/AdditionalInfo"
import InformationsForm from "./Edit/InformationsForm"
import LevelsForm from "./Edit/LevelsForm"
import getRequiredForEnablingFields from "./Utils/GetRequiredForEnablingFields"
import isExternal from "./Utils/IsExternal"
import isUserWithExternalPromotion from "./Utils/IsUserWithExternalPromotion"
import { parseUserLevelDate } from "./Utils/ParseUserLevelDate"
import sortByStartDate from "./Utils/SortByStartDate"

export default withRouter(function HCEdit({ genderValues, ...props }) {
  const [userLevels, setUserLevels] = useState()

  let [isSelectingExternalLevel, setIsSelectingExternalLevel] = useState(
    isExternal(props.user?.currentLevel?.code, props.levels)
  )

  useEffect(() => {
    fetchUserLevels()
  }, [props.match.params.id])

  const fetchUserLevels = () => {
    getLevels(props.match.params.id).then(({ data }) => {
      let userLvls = Array.isArray(data) ? data : []
      userLvls.forEach((lvl) => {
        lvl.cost = lvl.cost * 8
      })
      let parsedLevels = []
      userLvls.forEach((lvl) => {
        parsedLevels.push(parseUserLevelDate(lvl))
      })
      setUserLevels(sortByStartDate(parsedLevels))
    })
  }

  const { errors, setErrors, forms } = props

  const header = {
    display: "flex",
    felxDirection: "row",
    justifyContent: "space-between"
  }
  const tabContainer = {
    maxWidth: "1100px"
  }

  function registerForm(name, form) {
    if (!form) {
      return
    }
    forms[name] = form
  }

  const errorsInFields = (fields) => {
    let result = []
    errors.forEach((error) => {
      fields.forEach((field) => {
        if (field === error.field) {
          result.push(error)
        }
      })
    })
    return result
  }
  const informationsTabFields = [
    "firstName",
    "lastName",
    "email",
    "name",
    "extId",
    "joiningDate",
    "leavingDate",
    "organization",
    "bipRole",
    "office",
    "competence",
    "legalEntity"
  ]
  const additionalInfoTabFields = [
    "workedYeardInBip",
    "workedYearsBeforeBip",
    "totWorkedYears",
    "cheargeability",
    "createdAt",
    "updatedAt",
    "notes",
    "contract",
    "contractType",
    "skills",
    "contractEnd",
    "orderEnd",
    "xTechAccountable",
    "supplier",
    "equivalentBipLevel"
  ]

  const levelsTabFields = ["code", "name", "currentLevel", "startDate", "cost"]

  const tabsContent = () => {
    return [
      {
        title: (
          <Fragment>
            {formatMessage("hc-informations-tab")}
            {errorsInFields(informationsTabFields).length > 0 ? (
              <span className="text-danger ml-2 lnr lnr-warning"></span>
            ) : (
              ""
            )}
          </Fragment>
        ),
        content: () => {
          return (
            <Fragment>
              <InformationsForm
                registerForm={registerForm}
                errors={errors}
                setErrors={setErrors}
                user={props.user}
                organizations={props.organizations}
                bipRoles={props.bipRoles}
                offices={props.offices}
                competences={props.competences}
                setUser={props.setUser}
                container={tabContainer}
                validateForms={props.validateForms}
                levels={props.levels}
                globalCompetences={props.globalCompetences}
                circleValues={props.circleValues}
                legalEntityValues={props.legalEntityValues}
                genderValues={genderValues}
              />
            </Fragment>
          )
        }
      },
      {
        title: (
          <Fragment>
            {formatMessage("hc-additional-info-tab")}
            {errorsInFields(additionalInfoTabFields).length > 0 ? (
              <span className="text-danger ml-2 lnr lnr-warning"></span>
            ) : (
              ""
            )}
          </Fragment>
        ),
        content: () => {
          return (
            <Fragment>
              <AdditionalInfoForm
                registerForm={registerForm}
                user={props.user}
                setUser={props.setUser}
                userLevels={userLevels}
                bipRoles={props.bipRoles}
                userNames={props.userNames}
                contractTypes={props.contractTypes}
                adbS={props.adbS}
                suppliers={props.suppliers}
                container={tabContainer}
                errors={props.errors}
                setErrors={setErrors}
                levels={props.levels}
                validateForms={props.validateForms}
                tags={props.tags}
              />
            </Fragment>
          )
        }
      },
      {
        title: (
          <Fragment>
            {formatMessage("hc-levels-tab")}
            {errorsInFields(levelsTabFields).length > 0 ? (
              <span className="text-danger ml-2 lnr lnr-warning"></span>
            ) : (
              ""
            )}
          </Fragment>
        ),
        content: () => {
          return (
            <LevelsForm
              user={props.user}
              setUser={props.setUser}
              levels={props.levels}
              userLevels={userLevels}
              setUserLevels={setUserLevels}
              container={tabContainer}
              getLevelByCode={props.getLevelByCode}
              setShowConfirmChangePopup={setShowConfirmChangePopup}
              isSelectingExternalLevel={isSelectingExternalLevel}
              updateUserAndHandleResponse={props.updateUserAndHandleResponse}
            />
          )
        }
      }
    ]
  }

  function getTabs() {
    return tabsContent().map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content(),
      key: index
    }))
  }

  function getSubTitle() {
    return props.user.currentLevel
      ? props.user.currentLevel.name + " - " + props.user.currentLevel.code
      : formatMessage("hc-no-current-level")
  }

  function canBeEnabled() {
    // if the user has no current level he can't be anabled
    if (!props.user.currentLevel) {
      return false
    }
    // if the user is already enabled you can disable him
    if (props.user.enabled) {
      return true
    }

    let _canBeEnabled = true

    const checkExternalFields =
      isExternal(props.user.currentLevel.code, props.levels) ||
      isUserWithExternalPromotion(userLevels, props.levels)

    const fieldsRequiredForEnabling = getRequiredForEnablingFields(
      checkExternalFields
    )

    fieldsRequiredForEnabling.forEach((field) => {
      if (!props.user[field]) {
        _canBeEnabled = false
      }
    })

    return _canBeEnabled
  }

  const [showConfirmChangePopup, setShowConfirmChangePopup] = useState(false)

  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  const getEnabledIdValue = () => {
    if (!props.user) {
      return null
    }
    return props.user.enabled ? 2 : 1
  }

  return (
    <Fragment>
      <SwitchToExternalOrInternalPopup
        setUser={props.setUser}
        user={props.user}
        setShowConfirmChangePopup={setShowConfirmChangePopup}
        showConfirmChangePopup={showConfirmChangePopup}
        updateUserAndHandleResponse={props.updateUserAndHandleResponse}
        levels={props.levels}
        userLevels={userLevels}
        setIsSelectingExternalLevel={setIsSelectingExternalLevel}
      />
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <Row form>
              <Col md={12}>
                <div style={header}>
                  <div>
                    <h4>{props.user.name}</h4>
                    <div className="d-flex">
                      <p className="mt-1">{getSubTitle()} </p>
                      {props.user.currentLevel ? (
                        <div className="ml-2">
                          <div className="ml-1 px-1 border border-primary text-primary">
                            {isExternal(props.user.currentLevel?.code, props.levels)
                              ? formatMessage("hc-external")
                              : formatMessage("hc-internal")}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    id="enableUser"
                    onMouseEnter={() => {
                      setTooltipVisibility(true)
                    }}
                    onMouseLeave={() => {
                      setTooltipVisibility(false)
                    }}
                    style={{
                      height: "fit-content",
                      zIndex: 1000,
                      padding: "30px 30px 10px 10px"
                    }}
                  >
                    <MultiSwitchComponent
                      items={[
                        {
                          id: 1,
                          text: formatMessage("hc-disabled"),
                          activeClass: "btn-danger",
                          nonActiveClass: "btn-outline-primary"
                        },
                        {
                          id: 2,
                          text: formatMessage("hc-enabled"),
                          activeClass: "btn-success",
                          nonActiveClass: "btn-outline-primary"
                        }
                      ]}
                      onValueChanged={(id) => {
                        props.setErrors([])
                        props.setUser((prevUser) => ({
                          ...prevUser,
                          enabled: id === 2
                        }))
                      }}
                      value={getEnabledIdValue()}
                      disabled={!canBeEnabled()}
                    />
                    {canBeEnabled() ? (
                      ""
                    ) : (
                      <Tooltip
                        target="#enableUser"
                        visible={tooltipVisibility}
                        closeOnOutsideClick={false}
                      >
                        {formatMessage("hc-tooltip-enabled")}
                      </Tooltip>
                    )}
                  </div>
                </div>

                <Tabs
                  tabsWrapperClass="body-tabs body-tabs-layout"
                  transform={false}
                  showInkBar={true}
                  items={getTabs()}
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  )
})
