import moment from "moment"
import { find } from "underscore"

export const SET_ALL_JOBS = "SET_ALL_JOBS"

export const setAllJobs = (allJobs) => ({
  type: SET_ALL_JOBS,
  payload: allJobs
})

const initialState = {
  all: [],
  forReview: []
}

const getGroupedField = (j, wbsTypes) => {
  if (j.wbs.type === "1") {
    return j.customer || "NOT DEFINED"
  } else {
    const type = find(wbsTypes, (jt) => jt.code === j.wbs.type)
    return (type && type.name) || "NOT DEFINED"
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ALL_JOBS":
      return {
        ...state,
        all: action.payload.jobs,
        forReview: action.payload.jobs.map((j) => {
          j.isActive =
            j.startDate != null && j.endDate != null
              ? moment(action.payload.selectedPeriod?.calendarStartDate).isBetween(
                  j.startDate,
                  j.endDate,
                  "M",
                  "[]"
                )
              : false

          return {
            id: j._id,
            wbs: j.wbs.code,
            label: j.wbs.code + " - " + j.description,
            suggestionLabel:
              j.wbs.code +
              (j.wbs.aliasCode ? " - " + j.wbs.aliasCode : "") +
              " - " +
              j.description,
            wbsAliasCode: j.wbs.aliasCode,
            isDescriptionMandatory: j.isDescriptionMandatory,
            type: getGroupedField(j, action.payload.wbsTypes),
            description: j.description,
            disabled: !j.isActive || j.isDeleted,
            isTempPlaced: j.isTempPlaced
          }
        }),
        forTimesheet: action.payload.jobs.map((j) => {
          j.isActive =
            j.startDate != null && j.endDate != null
              ? moment(action.payload.selectedPeriod?.calendarStartDate).isBetween(
                  j.startDate,
                  j.endDate,
                  "M",
                  "[]"
                )
              : false

          return {
            id: j._id,
            wbs: j.wbs.code,
            label: j.wbs.code + " - " + j.description,
            suggestionLabel:
              j.wbs.code +
              (j.wbs.aliasCode ? " - " + j.wbs.aliasCode : "") +
              " - " +
              j.description,
            wbsAliasCode: j.wbs.aliasCode,
            isDescriptionMandatory: j.isDescriptionMandatory,
            type: getGroupedField(j, action.payload.wbsTypes),
            description: j.description,
            disabled: !j.isActive || j.isDeleted,
            isTempPlaced: j.isTempPlaced
          }
        })
      }

    default:
      return state
  }
}
