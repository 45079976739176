import { start } from "@popperjs/core"
import { apiService } from "./APIServices"

import axios from "axios"

export const getAllWbs = async (
  expand,
  enablePagination,
  page,
  size,
  filters, //{fiscalYear, accountable type, status, isActive, isFinal, isTempPlaced, adb}
  sorting, //{orderBy, sortingOrder}
  includeDeputyAccountables,
  includeSupporters,
) => {
  return axios.get(apiService.wbs, {
    params: {
      expand,
      enablePagination,
      page,
      size,
      fiscalYear: filters?.fiscalYear,
      accountable: filters?.accountable,
      jobOwner: filters?.jobOwner,
      deliveryManager: filters?.deliveryManager,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      type: filters?.type,
      status: filters?.status,
      isActive: filters?.isActive,
      isTempPlaced: filters?.isTempPlaced,
      isFinal: filters?.isFinal,
      adb: filters?.adb,
      orderBy: sorting?.orderBy,
      sortingOrder: sorting?.sortingOrder,
      includeDeputyAccountables,
      includeSupporters,
    }
  })
}

export const searchWbs = async (
  enablePagination,
  page,
  size,
  filters, //{term, fiscalYear, accountable type, status, isActive, isFinal, isTempPlaced adb}
  sorting, //{orderBy, sortingOrder}
  includeDeputyAccountables,
  includeSupporters
) => {
  return axios.get(`${apiService.search}/wbs`, {
    params: {
      q: filters?.term,
      enablePagination,
      page,
      size,
      fiscalYear: filters?.fiscalYear,
      accountable: filters?.accountable,
      jobOwner: filters?.jobOwner,
      deliveryManager: filters?.deliveryManager,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      type: filters?.type,
      status: filters?.status,
      isActive: filters?.isActive,
      isTempPlaced: filters?.isTempPlaced,
      isFinal: filters?.isFinal,
      adb: filters?.adb,
      orderBy: sorting?.orderBy,
      sortingOrder: sorting?.sortingOrder,
      includeDeputyAccountables,
      includeSupporters
    }
  })
}

export const getWbs = async (
  wbsId,
  expand = true,
  includeDeputyAccountables = false,
  includeSupporters = false
) => {
  return axios.get(`${apiService.wbs}/${wbsId}`, {
    params: {
      expand,
      includeDeputyAccountables,
      includeSupporters
    }
  })
}

export const createWbs = async (wbs) => {
  return axios.post(apiService.wbs, wbs)
}

export const updateWbs = async (wbs) => {
  return axios.put(apiService.wbs + `/${wbs._id}`, wbs)
}

export const deleteWbs = async (wbsId) => {
  return axios.delete(apiService.wbs + `/${wbsId}`)
}

export const exportExcel = () => {
  return axios.get(apiService.query + `/wbsExport`, {
    responseType: "arraybuffer"
  })
}

export const getWbsSuggestions = (isEditingOrCreating, areaId) => {
  return axios.get(apiService.wbs + `/fields-suggestions/${areaId}`, {
    params: {
      isEditingOrCreating: isEditingOrCreating,
    }
  })
}
