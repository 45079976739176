import "bootstrap/dist/css/bootstrap.min.css"
import { Tooltip } from "devextreme-react/tooltip"
import { formatMessage } from "devextreme/localization"
import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Row } from "reactstrap"
import "./../../../../style/dx.material.bip-scheme.css"
import MultiSwitchComponent from "./Components/MultiSwitchComponent"
import { HCCreateForm } from "./HCCreateForm"
import getRequiredForEnablingFields from "./Utils/GetRequiredForEnablingFields"
import isExternal from "./Utils/IsExternal"

export default function HCCreate({
  formRef,
  errors,
  validator,
  spinner,

  user,
  setUser,
  enabled,
  setEnabled,

  organizations,
  levels,
  bipRoles,
  offices,
  competences,
  contractTypes,
  adbS,
  suppliers,
  globalCompetences,
  circleValues,
  legalEntityValues,
  genderValues,
  userNames,

  getLevelByCode
}) {
  const header = {
    display: "flex",
    felxDirection: "row",
    justifyContent: "space-between"
  }

  const [tooltipVisibility, setTooltipVisibility] = useState(false)
  const [enabledDisabledSwitchId, setEnabledDisabledSwitchId] = useState(null)
  const [canEnable, setCanEnable] = useState(false)

  useEffect(() => {
    const isUserEnabled = canEnable ? enabled : false
    setCanEnable(canBeEnabled())
    setEnabled(isUserEnabled)
    setEnabledDisabledSwitchId(isUserEnabled ? 2 : 1)
  }, [canEnable, user])

  const canBeEnabled = () => {
    let _canBeEnabled = true
    if (
      !user.currentLevel ||
      !user.currentLevel?.code ||
      !user.currentLevel?.name ||
      !user.currentLevel?.cost ||
      !user.currentLevel?.startDate ||
      user.currentLevel?.isPromotion
    ) {
      _canBeEnabled = false
    }
    const checkExternalFields = isExternal(user.currentLevel.code, levels)
    const fieldsRequiredForEnabling = getRequiredForEnablingFields(
      checkExternalFields
    )
    fieldsRequiredForEnabling.forEach((field) => {
      if (!user[field] && !(user["isTeam"] && field === "email")) {
        _canBeEnabled = false
      }
    })
    return _canBeEnabled
  }

  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody>
          <Form>
            <Row form>
              <Col md={12}>
                <div style={header}>
                  <div>
                    <h4>{formatMessage("hc-create-title")}</h4>
                  </div>
                  {spinner ? (
                    ""
                  ) : (
                    <div
                      id="enableUser"
                      onMouseEnter={() => {
                        setTooltipVisibility(true)
                      }}
                      onMouseLeave={() => {
                        setTooltipVisibility(false)
                      }}
                      style={{
                        height: "fit-content",
                        zIndex: 1000,
                        padding: "30px 30px 10px 10px"
                      }}
                    >
                      <MultiSwitchComponent
                        items={[
                          {
                            id: 1,
                            text: formatMessage("hc-disabled"),
                            activeClass: "btn-danger",
                            nonActiveClass: "btn-outline-primary"
                          },
                          {
                            id: 2,
                            text: formatMessage("hc-enabled"),
                            activeClass: "btn-success",
                            nonActiveClass: "btn-outline-primary"
                          }
                        ]}
                        onValueChanged={(id) => {
                          setEnabled(id === 2)
                          setEnabledDisabledSwitchId(id)
                        }}
                        value={enabledDisabledSwitchId}
                        disabled={!canEnable}
                      />
                      {canEnable ? (
                        ""
                      ) : (
                        <Tooltip
                          target="#enableUser"
                          visible={tooltipVisibility}
                          closeOnOutsideClick={false}
                        >
                          {formatMessage("hc-tooltip-enabled")}
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>

                {spinner ? (
                  "loading..."
                ) : (
                  <HCCreateForm
                    errors={errors}
                    validator={validator}
                    formRef={formRef}
                    user={user}
                    setUser={setUser}
                    levels={levels}
                    organizations={organizations}
                    bipRoles={bipRoles}
                    offices={offices}
                    competences={competences}
                    globalCompetences={globalCompetences}
                    circleValues={circleValues}
                    legalEntityValues={legalEntityValues}
                    genderValues={genderValues}
                    contractTypes={contractTypes}
                    adbS={adbS}
                    userNames={userNames}
                    suppliers={suppliers}
                    getLevelByCode={getLevelByCode}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  )
}
