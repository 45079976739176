import "bootstrap/dist/css/bootstrap.min.css"
import { formatMessage } from "devextreme/localization"
import moment from "moment"
import React, { Fragment, useEffect } from "react"
import { withRouter } from "react-router"
import { Card, CardBody, Col, Row } from "reactstrap"
import "./../../../../style/dx.material.bip-scheme.css"
import { calcChargeability } from "./Utils/CalcChargeability"
import { calcExtJobTitle } from "./Utils/CalcExtJobTitle"
import isExternal from "./Utils/IsExternal"
import { roundSecondDecimal } from "./Utils/RoundSecondDecimal"

export default withRouter(function HCShow({
  user,
  userLevels,
  userNames,
  offices,
  organizations,
  levels
}) {
  const getIsUserExternalElement = () => {
    return isExternal(user.currentLevel?.code, levels) ? (
      <span className="ml-3 p-1 border border-primary text-primary">
        {formatMessage("hc-External")}
      </span>
    ) : (
      ""
    )
  }

  const getIsUserEnabledElement = () => {
    return user.enabled ? (
      <span className="ml-3 p-1 border border-success text-success">
        {formatMessage("hc-enabled")}
      </span>
    ) : (
      <span className="ml-3 p-1 border border-warning text-warning">
        {formatMessage("hc-disabled")}
      </span>
    )
  }

  const getLevelStateElement = (level) => {
    return (
      <>
        {isCurrentLevel(level) ? (
          <span className="p-1 border border-success text-success">
            {formatMessage("hc-current-level-label")}
          </span>
        ) : (
          ""
        )}
        {isPromotion(level) ? (
          <span className="p-1 border border-warning text-warning">
            {formatMessage("hc-promotion-level-label")}
          </span>
        ) : (
          ""
        )}
      </>
    )
  }

  const formatDateText = (date) => {
    if (!date) return ""
    return moment(date).format("DD-MM-YYYY")
  }

  const filterById = (array, id) => {
    return array.filter((item) => {
      return item.id === id
    })
  }

  const getOfficeText = () => {
    const office = filterById(offices, user.office)[0]

    if (office) {
      return office.name + " - " + office.code
    }

    return
  }

  const getOrganizationText = () => {
    return filterById(organizations, user.organization)[0]?.name
  }

  const getWorkedYearsInBipText = () => {
    return roundSecondDecimal(user.workedYearsInBip)
  }

  const getWorkedYearsBeforeBipText = () => {
    return roundSecondDecimal(user.workedYearsBeforeBip)
  }

  const getTotalWorkedYearsText = () => {
    return roundSecondDecimal(user.workedYearsInBip + user.workedYearsBeforeBip)
  }

  const getReviewReaderText = () => {
    return filterById(userNames, user.reviewReader)[0]?.name
  }

  const getExtJobTitleText = () => {
    return calcExtJobTitle(user.currentLevel?.code, user.competence, levels)
  }

  const getxTechAccountableText = () => {
    return userNames.find((userName) => {
      return userName.id === user.xTechAccountable
    })?.name
  }

  const isCurrentLevel = (level) => {
    if (!user.currentLevel) return false
    return (
      level.code === user.currentLevel.code &&
      level.startDate === user.currentLevel.startDate
    )
  }

  const isPromotion = (level) => {
    if (!user.currentLevel) return true
    return new Date(user.currentLevel.startDate) < new Date(level.startDate)
  }

  return (
    <Fragment>
      <div className="pb-4">
        <Row>
          <Col
            md={12}
            className="d-flex flex-row justify-content-start align-items-end"
          >
            <h2>{user.name}</h2>
            <p>{getIsUserExternalElement()}</p>
            <p>{getIsUserEnabledElement()}</p>
          </Col>
        </Row>
      </div>
      <div className="d-flex flex-row justify-content-start align-items-stretch w-100">
        <div style={{ flex: "1" }}>
          <div className="d-flex flex-column justify-content-between">
            <Card className="mb-3 table-responsive">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex flew-row">
                      <div className="w-25">
                        <h5>{formatMessage("hc-basic-informations")}</h5>
                      </div>
                      <div>
                        <div className="d-flex flex-row pt-2">
                          <div className="pl-5">
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-name")}:
                              </span>{" "}
                              {user.name}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-firstname")}:
                              </span>{" "}
                              {user.firstName}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-lastname")}:
                              </span>{" "}
                              {user.lastName}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-email")}:
                              </span>{" "}
                              {user.email}
                            </p>
                          </div>
                          <div className="pl-5">
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-ext-id")}:
                              </span>{" "}
                              {user.extId}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-hiring-date")}:
                              </span>{" "}
                              {formatDateText(user.joiningDate)}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-leaving-date")}:
                              </span>{" "}
                              {formatDateText(user.leavingDate)}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("gender")}:
                              </span>{" "}
                              {user.gender}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mb-3 table-responsive">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex flew-row">
                      <div className="w-25">
                        <h5>{formatMessage("hc-organizational-informations")}</h5>
                      </div>
                      <div className="pl-5">
                        <p className="pb-2 pt-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-legal-entity")}:
                          </span>{" "}
                          {user.legalEntity}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-organization")}:
                          </span>{" "}
                          {getOrganizationText()}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-bip-role")}:
                          </span>{" "}
                          {user.bipRole}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-area")}:
                          </span>{" "}
                          {user.area === "Other"
                            ? formatMessage("areas-other")
                            : user.area}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-area-code")}:
                          </span>{" "}
                          {user.areaShortcode}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-office")}:
                          </span>{" "}
                          {getOfficeText()}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-competence")}:
                          </span>{" "}
                          {user.competence}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-global-competence")}:
                          </span>{" "}
                          {user.globalCompetence === "Other"
                            ? formatMessage("global-competence-other")
                            : user.globalCompetence}
                        </p>
                        <p className="pb-2">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-circle")}:
                          </span>{" "}
                          {user.circle?.join(", ")}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mb-3 table-responsive">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex flew-row">
                      <div className="w-25">
                        <h5>{formatMessage("hc-additional-informations")}</h5>
                      </div>
                      <div>
                        <div className="d-flex flex-row pt-2">
                          <div className="pl-5">
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {" "}
                                {formatMessage("hc-field-worked-years-before-bip")}:
                              </span>{" "}
                              {getWorkedYearsBeforeBipText()}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-worked-years-in-bip")}:
                              </span>{" "}
                              {getWorkedYearsInBipText()}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-total-worked-years")}:
                              </span>{" "}
                              {getTotalWorkedYearsText()}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-sial-code")}:
                              </span>{" "}
                              {user.sialCode}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-review-reader")}:
                              </span>{" "}
                              {getReviewReaderText()}
                            </p>
                          </div>
                          <div className="pl-5">
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-chargeability")}:
                              </span>{" "}
                              {calcChargeability(user)}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-ext-job-title")}:
                              </span>{" "}
                              {getExtJobTitleText()}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-created-at")}:
                              </span>{" "}
                              {formatDateText(user.created_at)}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-updated-at")}:
                              </span>{" "}
                              {formatDateText(user.updated_at)}
                            </p>
                          </div>
                        </div>
                        <p className="pb-2 pl-5">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-tags")}
                          </span>{" "}
                          {user.tags?.join(", ")}
                        </p>
                        <p className="pb-2 pl-5">
                          <span className="font-weight-bold">
                            {formatMessage("hc-field-notes")}:
                          </span>{" "}
                          {user.notes}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mb-3 table-responsive">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex flew-row">
                      <div className="w-25">
                        <h5>{formatMessage("hc-external-users-informations")}</h5>
                      </div>
                      <div>
                        <div className="d-flex flex-row pt-2">
                          <div className="pl-5">
                            <p className="pb-2 pt-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-contract")}:
                              </span>{" "}
                              {user.contract}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-contract-type")}:
                              </span>{" "}
                              {user.contractType}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-skills")}:
                              </span>{" "}
                              {user.skills}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-contract-end")}:
                              </span>{" "}
                              {formatDateText(user.contractEnd)}
                            </p>
                          </div>
                          <div className="pl-5">
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-order-end")}:
                              </span>{" "}
                              {formatDateText(user.orderEnd)}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-xtech-accountable")}:
                              </span>{" "}
                              {getxTechAccountableText()}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-supplier")}:
                              </span>{" "}
                              {user.supplier}
                            </p>
                            <p className="pb-2">
                              <span className="font-weight-bold">
                                {formatMessage("hc-field-equivalent-bip-level")}:
                              </span>{" "}
                              {user.equivalentBipLevel}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="ml-5" style={{ flex: "1" }}>
          <div>
            <Card className="mb-3 table-responsive">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="pl-2">
                      <h5>{formatMessage("hc-levels-tab")}</h5>
                    </div>
                    {userLevels?.length === 0 ? (
                      <div className="p-3 w-100 d-flex justify-content-center align-items-center">
                        <div></div>
                      </div>
                    ) : (
                      <div className="pl-2 pt-3 d-flex flex-column">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="p-3" scope="col">
                                {formatMessage("hc-status")}
                              </th>
                              <th className="p-3" scope="col">
                                {formatMessage("hc-field-level-name")}
                              </th>
                              <th className="p-3" scope="col">
                                {formatMessage("hc-field-code")}
                              </th>
                              <th className="p-3" scope="col">
                                {formatMessage("hc-field-start-date")}
                              </th>
                              <th className="p-3" scope="col">
                                {formatMessage("hc-field-cost")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userLevels?.map((level) => {
                              return (
                                <tr key={level._id}>
                                  <td className="p-3">
                                    {getLevelStateElement(level)}
                                  </td>
                                  <td className="p-3">{level.name}</td>
                                  <td className="p-3">{level.code}</td>
                                  <td className="p-3">
                                    {formatDateText(level.startDate)}
                                  </td>
                                  <td className="p-3">€{level.cost}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  )
})
