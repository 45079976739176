import { formatMessage } from "devextreme/localization"
import { useAppAbility } from "../../../Context/ability"
import PageTitle from "../../../Layout/AppMain/PageTitle"
import AccountableFilter from "./components/AccountableFilter"
import DeliveryManagerFilter from "./components/DeliveryManagerFilter"
import JobOwnerFilter from "./components/JobOwnerFilter"
import AddWbsButton from "./components/AddWbsButton"
import ClearFiltersButton from "./components/ClearFiltersButton"
import { ErrorBlock } from "./components/ErrorBlock"
import ExportWbsButtonAsXLSX from "./components/ExportWbsButtonAsXLSX"
import JobAndWbsTable from "./components/JobAndWbsTable"
import Paginator from "./components/Paginator"
import RefreshTableButton from "./components/RefreshTableButton"
import SearchFilter from "./components/SearchFilter"
import { Spinner } from "./components/Spinner"
import YearFilter from "./components/YearFilter"
import { useJobAndWbsData } from "./hooks/useJobAndWbsData"
import { Collapse, Button } from "reactstrap"
import DatePickerInput from "./components/input/DatePickerInput"
import React, { useState } from "react"
import Icon from "./components/Icon"

export default function JobAndWbs() {
  const {
    pageSize,
    setPageSize,
    currentPage,
    lastPage,
    totalCount,
    amountCurrentlyVisualizing,
    onPageChange,
    getFilterValue,
    setFilterValue,
    orderBy,
    setOrderBy,
    sortingOrder,
    setSortingOrder,
    pinnedUntil,
    setPinnedUntil,
    resetAllFilters,
    refetch,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    queryWbsError
  } = useJobAndWbsData()

  const { can } = useAppAbility()

  const canManageWBS = can("manage", "Wbs")

  const [isFiltersCollapseOpen, setIsFiltersCollapseOpen] = useState(false)
  const toggle = () => setIsFiltersCollapseOpen(!isFiltersCollapseOpen)
  return (
    <>
      <div className="job-and-wbs-container">
        <PageTitle
          heading={formatMessage("admin-job-wbs-title")}
          subheading=""
          icon="pe-7s-study text-success"
        />

        {/* TOOLBAR */}
        {!queryWbsError && (isSuccess || isLoading) ? (
          <>
            {/* ADD WBS BUTTON, SEARCH, YEAR FILTER, ACCOUNTABLE FILTER, BUTTONS */}
            <div className="d-flex flex-row justify-content-between align-items-center mb-4">
              <div className="d-flex flex-row w-100" style={{ zIndex: 100001 }}>
                {canManageWBS && (
                  <div className="col-2 px-1 d-flex">
                    <AddWbsButton extended={true} />
                    {(isLoading || isFetching) && (
                      <span className="text-primary m-auto">
                        <Spinner className={"mx-1"} />{" "}
                        {formatMessage("jw-Loading...")}
                      </span>
                    )}
                  </div>
                )}

                <SearchFilter
                  placeholder={formatMessage("jw-Search...")}
                  className="col-4 px-1"
                  termFilter={getFilterValue("term")}
                  setTermFilter={(value) => {
                    setFilterValue("term", value)
                  }}
                />
                <YearFilter
                  className="col-1 px-1"
                  yearFilter={getFilterValue("fiscalYear")}
                  setYearFilter={(value) => {
                    setFilterValue("fiscalYear", value)
                  }}
                />
                <AccountableFilter
                  className="col-2 px-1"
                  accountableFilter={getFilterValue("accountable")}
                  setAccountableFilter={(value) => {
                    setFilterValue("accountable", value)
                  }}
                />

                <div className="col-1 px-1 text-nowrap">
                  <Button color="" onClick={toggle}>
                    {isFiltersCollapseOpen ? (
                      <>
                        <Icon iconRelativePath="monade/arrow-up" />
                        Hide Filters
                      </>
                    ) : (
                      <>
                        <Icon iconRelativePath="monade/arrow-down" />
                        More Filters
                      </>
                    )}
                  </Button>
                </div>

                <div className="col-2 px-1 d-flex justify-content-end">
                  <ClearFiltersButton resetAllFilters={resetAllFilters} />
                  <RefreshTableButton refetch={refetch} />
                  <ExportWbsButtonAsXLSX />
                </div>
              </div>
            </div>

            {/* HIDDEN ROW: JOB OWNER FILTER, DELIVERY MANAGER FILTER, START/END DATE */}
            <Collapse isOpen={isFiltersCollapseOpen}>
              <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                <div className="d-flex flex-row w-100" style={{ zIndex: 100000 }}>
                  <span className="col-2"></span>
                  <JobOwnerFilter
                    className="col-2 px-1"
                    jobOwnerFilter={getFilterValue("jobOwner")}
                    setJobOwnerFilter={(value) => {
                      setFilterValue("jobOwner", value)
                    }}
                  />

                  <DeliveryManagerFilter
                    className="col-2 px-1"
                    deliveryManagerFilter={getFilterValue("deliveryManager")}
                    setDeliveryManagerFilter={(value) => {
                      setFilterValue("deliveryManager", value)
                    }}
                  />

                  <span className="col-1 text-right my-auto font-weight-bold">
                    Date Range
                  </span>
                  <DatePickerInput
                    readOnly={false}
                    className="col-1 px-1"
                    value={
                      getFilterValue("startDate")
                        ? new Date(getFilterValue("startDate"))
                        : undefined
                    }
                    label={""}
                    onChange={(value) =>
                      setFilterValue("startDate", value?.toISOString())
                    }
                  />

                  <DatePickerInput
                    readOnly={false}
                    className="col-1 px-1"
                    value={
                      getFilterValue("endDate")
                        ? new Date(getFilterValue("endDate"))
                        : undefined
                    }
                    label={""}
                    onChange={(value) =>
                      setFilterValue("endDate", value?.toISOString())
                    }
                  />
                </div>
              </div>
            </Collapse>
          </>
        ) : (
          ""
        )}

        {/* JOB AND WBS TABLE */}
        {queryWbsError ? (
          <div className="text-center my-4">
            <h5 className="mb-4 d-block">
              Error while loading data for Jobs and WBS
            </h5>
            <RefreshTableButton refetch={refetch} />
          </div>
        ) : (
          ""
        )}
        {!queryWbsError && (isSuccess || isLoading) ? (
          <div style={{ width: "calc(100vw - 140px)" }}>
            <JobAndWbsTable
              loading={isLoading}
              data={data || []}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              sortingOrder={sortingOrder}
              setSortingOrder={setSortingOrder}
              getFilterValue={getFilterValue}
              setFilterValue={setFilterValue}
              pinnedUntil={pinnedUntil}
              setPinnedUntil={setPinnedUntil}
              isFetching={isFetching}
            />
            <Paginator
              className="my-2 mr-3"
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              totalCount={totalCount}
              amountCurrentlyVisualizing={amountCurrentlyVisualizing}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
