import {
  CustomRule,
  Form,
  GroupItem,
  Item,
  Label,
  SimpleItem
} from "devextreme-react/form"
import { formatMessage } from "devextreme/localization"
import { Fragment, useEffect, useState } from "react"
import _ from "underscore"
import FormGroupTitle from "./Components/FormGroupTitle"
import MultiSwitchComponent from "./Components/MultiSwitchComponent"
import Checkbox from "../../JobAndWbs/components/input/Checkbox"

export function HCCreateForm({
  errors,
  validator,
  formRef,

  user,
  setUser,

  levels,
  organizations,
  bipRoles,
  areas,
  offices,
  competences,
  globalCompetences,
  circleValues,
  legalEntityValues,
  genderValues,
  contractTypes,
  adbS,
  userNames,
  suppliers,

  getLevelByCode
}) {
  const [levelStep, setLevelStep] = useState(0)
  const [showExternalUserForm, setShowExternalUserForm] = useState(false)

  const container = {
    maxWidth: "1100px"
  }

  useEffect(() => {
    if (user.currentLevel.isExternal == null) {
      setShowExternalUserForm(false)
      setLevelStep(0)
    } else {
      setShowExternalUserForm(Boolean(user.currentLevel.isExternal))
    }

    if (user.currentLevel.startDate == null) {
      setLevelStep(1)
    }
  }, [user.currentLevel])

  const nextStep = () => {
    setLevelStep((prev) => prev + 1)
  }

  const [isTeam, setIsTeam] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsTeam(event.target.checked)
  }

  const resetFormToStep = (stepNumber) => {
    let newLevelToSet = {}
    if (stepNumber <= 3) {
      newLevelToSet.cost = getLevelByCode(user.currentLevel?.code)?.cost || null
    }
    if (stepNumber <= 2) {
      newLevelToSet.code = null
      newLevelToSet.name = null
      newLevelToSet.cost = null
    }
    if (stepNumber <= 1) {
      newLevelToSet.startDate = null
      newLevelToSet.isPromotion = null
    }
    setUser((prevUser) => ({
      ...prevUser,
      currentLevel: {
        ...prevUser.currentLevel,
        ...newLevelToSet
      }
    }))
    setLevelStep(stepNumber)
  }

  const rule = (fieldName) => (
    <CustomRule
      validationCallback={() => {
        return Boolean(!errors.find((e) => e.field === fieldName))
      }}
      message={errors.find((e) => e.field === fieldName)?.message ?? ""}
    ></CustomRule>
  )

  const isOrganizationDisabled = (organization) => {
    return organization.isDeleted || !organization.owner
  }

  const getName = (firstName, lastName) => {
    return `${firstName || ""} ${lastName || ""}`
  }

  const getMultiLevelSelectId = () => {
    if (
      user.currentLevel.isExternal === null ||
      user.currentLevel.isExternal === undefined
    ) {
      return null
    }
    return user.currentLevel.isExternal ? 1 : 2
  }

  const addDisplayPropertyToALevel = (l) => {
    return {
      ...l,
      display: `${l.name} - ${l.code}`
    }
  }

  const getDropDownLevels = levels
    .filter((l) => {
      return l.active
    })
    .filter((l) => {
      return user.currentLevel.isExternal ? l.isExternal : !l.isExternal
    })
    .map(addDisplayPropertyToALevel)

  const renderBasicInfoForm = () => {
    return (
      <GroupItem cssClass="with-separator pb-4" colCount={12}>
        <GroupItem colSpan={3}>
          <Item>
            <FormGroupTitle
              title={formatMessage("hc-header-basic-info")}
              required={true}
              requiredForEnabling={true}
            />
          </Item>
        </GroupItem>
        <GroupItem colCount={2} colSpan={9}>
          <SimpleItem
            dataField="firstName"
            editorOptions={{
              maxLength: 50,
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  name: getName(e.value?.trim(), user.lastName?.trim()),
                  firstName: e.value || ""
                }))
              },
              placeholder: `${formatMessage("name")}...`
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-firstname") + " *"}
            />
            {rule("firstName")}
          </SimpleItem>
          <SimpleItem
            dataField="lastName"
            editorOptions={{
              maxLength: 50,
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  name: getName(user?.firstName?.trim() || "", e.value.trim()),
                  lastName: e.value || ""
                }))
              },
              placeholder: `${formatMessage("lastName")}...`
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-lastname") + " *"}
            />
            {rule("lastName")}
          </SimpleItem>
          <SimpleItem
            dataField="email"
            editorOptions={{
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  email: e.value || null
                }))
              },
              placeholder: "example@mail-bip.com..."
            }}
          >
            <Label
              showColon={false}
              text={
                isTeam
                  ? formatMessage("hc-field-email-optional")
                  : formatMessage("hc-field-email") + " *"
              }
            />
            {rule("email")}
            <CustomRule
              validationCallback={(e) => {
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/
                return regex.test(e.value) || e.value === ""
              }}
              message={formatMessage("hc-error-invalid-mail-format")}
            />
          </SimpleItem>

          <SimpleItem
            dataField="name"
            editorOptions={{
              readOnly: true
            }}
          >
            <Label showColon={false} text={formatMessage("hc-field-name")} />
          </SimpleItem>

          <SimpleItem
            dataField="extId"
            editorOptions={{
              maxLength: 10,
              inputAttr: { style: "text-transform: uppercase" },
              placeholder: "XXXXXX",
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  extId: e.value || ""
                }))
              }
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-ext-id") + " *"}
            />
            {rule("extId")}
          </SimpleItem>
          <SimpleItem
            dataField="gender"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                { display: formatMessage("hc-placeholder-gender"), value: null },
                ...genderValues.map((gender) => {
                  return { display: gender, value: gender }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({ ...prevUser, gender: e.value }))
              },
              value: user.gender,
              placeholder: formatMessage("hc-placeholder-gender"),
              dropDownOptions: {
                title: formatMessage("gender"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label showColon={false} text={formatMessage("gender")} />
            {rule("area")}
          </SimpleItem>

          <SimpleItem>
            <Checkbox
              label={formatMessage("hc-create-team-account")}
              value={user.isTeam}
              onChange={(event) => {
                handleCheckboxChange(event)
                setUser((prevUser) => ({
                  ...prevUser,
                  isTeam: event.target.checked
                }))
              }}
            ></Checkbox>
          </SimpleItem>
        </GroupItem>
      </GroupItem>
    )
  }

  const renderLevelsForm = () => {
    return (
      <GroupItem cssClass="with-separator pb-4" colCount={12}>
        <GroupItem colSpan={3}>
          <Item>
            <FormGroupTitle
              title="Level"
              required={true}
              requiredForEnabling={false}
            />
          </Item>
        </GroupItem>
        <GroupItem colCount={13} colSpan={9}>
          <GroupItem colSpan={4}>
            <div
              style={{ marginTop: "40px" }}
              id="multi-switch-component"
              className="dx-field-item-content dx-field-item-content-location-bottom"
            >
              <MultiSwitchComponent
                items={[
                  {
                    id: 1,
                    text: formatMessage("hc-External"),
                    activeClass: "btn-primary",
                    nonActiveClass: "btn-outline-primary",
                    customStyle: { padding: "0.92rem 1rem" }
                  },
                  {
                    id: 2,
                    text: formatMessage("hc-Internal"),
                    activeClass: "btn-primary",
                    nonActiveClass: "btn-outline-primary",
                    customStyle: { padding: "0.92rem 1rem" }
                  }
                ]}
                onValueChanged={(id) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    currentLevel: {
                      ...prevUser.currentLevel,
                      isExternal: id === 1
                    }
                  }))
                  if (levelStep > 0) {
                    resetFormToStep(0)
                  } else {
                    nextStep()
                  }
                }}
                value={getMultiLevelSelectId()}
                disabled={false}
                size={"lg"}
              />
            </div>
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField="currentLevel.startDate"
              editorType="dxDateBox"
              editorOptions={{
                readOnly: levelStep < 1,
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    joiningDate: e.value,
                    currentLevel: {
                      ...prevUser.currentLevel,
                      startDate: e.value,
                      isPromotion: new Date(e.value) > new Date()
                    }
                  }))
                  if (levelStep > 1) {
                    resetFormToStep(2)
                  } else {
                    nextStep()
                  }
                }
              }}
            >
              <Label
                showColon={false}
                text={formatMessage("hc-field-joining-date") + " *"}
              />
              {rule("startDate")}
              {rule("joiningDate")}
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={4}>
            <SimpleItem
              dataField="currentLevel.code"
              editorType="dxLookup"
              editorOptions={{
                dataSource: getDropDownLevels,
                readOnly: levelStep < 2,
                onValueChanged: (e) => {
                  const level = getLevelByCode(e.value)
                  if (!level) {
                    return
                  }
                  setUser((prevUser) => ({
                    ...prevUser,
                    currentLevel: {
                      ...prevUser.currentLevel,
                      code: e.value,
                      name: level.name,
                      cost: level.cost
                    }
                  }))
                  if (levelStep > 2) {
                    resetFormToStep(3)
                  } else {
                    nextStep()
                  }
                },
                displayExpr: "display",
                valueExpr: "code",
                placeholder: formatMessage("hc-placeholder-level"),
                searchEnabled: true,
                showCancelButton: false,
                dropDownOptions: {
                  title: formatMessage("hc-field-levels"),
                  closeOnOutsideClick: true
                }
              }}
            >
              <Label
                showColon={false}
                text={formatMessage("hc-field-name-code") + " *"}
              />
              {rule("code")}
              {rule("currentLevel")}
            </SimpleItem>
          </GroupItem>
          <GroupItem colSpan={2}>
            <SimpleItem
              dataField="currentLevel.cost"
              editorType="dxNumberBox"
              editorOptions={{
                readOnly: levelStep < 3 || !user.currentLevel.isExternal,
                format: { currency: "EUR", type: "currency", precision: 3 },
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    currentLevel: {
                      ...prevUser.currentLevel,
                      cost: e.value
                    }
                  }))
                  if (levelStep > 3) {
                    resetFormToStep(4)
                  } else {
                    nextStep()
                  }
                }
              }}
            >
              <Label
                showColon={false}
                text={formatMessage("hc-field-cost") + " *"}
              />
              {rule("cost")}
            </SimpleItem>
          </GroupItem>
        </GroupItem>
      </GroupItem>
    )
  }

  const renderOrganizationalForm = () => {
    return (
      <GroupItem cssClass="with-separator pb-4" colCount={12}>
        <GroupItem colSpan={3}>
          <Item>
            <FormGroupTitle
              title={formatMessage("hc-header-organizational-info")}
              required={false}
              requiredForEnabling={true}
            />
          </Item>
        </GroupItem>
        <GroupItem colCount={2} colSpan={9}>
          <SimpleItem
            dataField="organization"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                {
                  name: formatMessage("hc-placeholder-organization"),
                  id: null
                },
                ...organizations
                  .filter((organization) => {
                    if (!user.organization && organization.isDeleted) {
                      return false
                    }
                    return user.organization === organization.id
                      ? true
                      : !organization.isDeleted
                  })
                  .map((organization) => {
                    return {
                      disabled: isOrganizationDisabled(organization),
                      ...organization
                    }
                  })
              ],
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  organization: e.value
                }))
              },
              displayExpr: "name",
              valueExpr: "id",
              value: user.organization,
              placeholder: formatMessage("hc-placeholder-organization"),
              dropDownOptions: {
                title: formatMessage("hc-field-organizations"),
                closeOnOutsideClick: true
              },
              searchEnabled: true,
              showCancelButton: false
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-organization") + " ■"}
            />
            {rule("organization")}
          </SimpleItem>

          <SimpleItem
            dataField="bipRole"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                { display: formatMessage("hc-placeholder-role"), value: null },
                ...bipRoles.map((role) => {
                  return { display: role, value: role }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({ ...prevUser, bipRole: e.value }))
              },
              value: user.bipRole,
              placeholder: formatMessage("hc-placeholder-role"),
              dropDownOptions: {
                title: formatMessage("hc-field-roles"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-bip-role") + " ■"}
            />
            {rule("bipRole")}
          </SimpleItem>
          <SimpleItem
            dataField="office"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                { display: formatMessage("hc-placeholder-office"), value: null },
                ...offices.map((office) => {
                  return {
                    display: office.name + " - " + office.code,
                    value: office.id
                  }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({ ...prevUser, office: e.value }))
              },
              value: user.office,
              placeholder: formatMessage("hc-placeholder-office"),
              dropDownOptions: {
                title: formatMessage("hc-field-offices"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-office") + " ■"}
            />
            {rule("office")}
          </SimpleItem>
          <SimpleItem
            dataField="competence"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                {
                  display: formatMessage("hc-placeholder-competence"),
                  value: null
                },
                ...competences.map((role) => {
                  return { display: role, value: role }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  competence: e.value
                }))
              },
              value: user.competence,
              placeholder: formatMessage("hc-placeholder-competence"),
              dropDownOptions: {
                title: formatMessage("hc-field-competences"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label showColon={false} text={formatMessage("hc-field-competence")} />
            {rule("competence")}
          </SimpleItem>
          <SimpleItem
            dataField="globalCompetence"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                {
                  display: formatMessage("hc-placeholder-global-competence"),
                  value: null
                },
                ...globalCompetences.map((comp) => {
                  if (comp === "Other") {
                    return {
                      display: formatMessage("global-competence-other"),
                      value: comp
                    }
                  }
                  return { display: comp, value: comp }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({
                  ...prevUser,
                  globalCompetence: e.value
                }))
              },
              value: user.globalCompetence,
              placeholder: formatMessage("hc-placeholder-global-competence"),
              dropDownOptions: {
                title: formatMessage("hc-field-global-competences"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-global-competence") + " ■"}
            />
            {rule("globalCompetence")}
          </SimpleItem>
          <SimpleItem
            dataField="circle"
            editorType="dxTagBox"
            editorOptions={{
              searchEnabled: false,
              dataSource: [
                ...circleValues.map((value) => {
                  return { display: value, value: value }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              value: user.circle,
              placeholder: formatMessage("hc-placeholder-circle"),
              onValueChanged: (e) => {
                if (!_.isEqual(user.circle, e.value)) {
                  setUser((prevUser) => ({
                    ...prevUser,
                    circle: e.value
                  }))
                }
              }
            }}
          >
            <Label showColon={false} text={formatMessage("hc-field-circle")} />
            {rule("circle")}
          </SimpleItem>
          <SimpleItem
            dataField="legalEntity"
            editorType="dxLookup"
            editorOptions={{
              dataSource: [
                {
                  display: formatMessage("hc-placeholder-legal-entity"),
                  value: null
                },
                ...legalEntityValues.map((legalEntity) => {
                  return {
                    display: legalEntity,
                    value: legalEntity
                  }
                })
              ],
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: (e) => {
                setUser((prevUser) => ({ ...prevUser, legalEntity: e.value }))
              },
              value: user.legalEntity,
              placeholder: formatMessage("hc-placeholder-legal-entity"),
              dropDownOptions: {
                title: formatMessage("hc-field-legal-entities"),
                closeOnOutsideClick: true
              },
              searchEnabled: false,
              showCancelButton: false
            }}
          >
            <Label
              showColon={false}
              text={formatMessage("hc-field-legal-entity") + " ■"}
            />
            {rule("legalEntity")}
          </SimpleItem>
        </GroupItem>
      </GroupItem>
    )
  }

  const renderExternalUserForm = () => {
    if (!showExternalUserForm) {
      return <></>
    }
    return (
      <Form labelLocation="top" formData={user}>
        <GroupItem cssClass="group" colCount={12}>
          <GroupItem colSpan={3}>
            <Item>
              <FormGroupTitle
                title={formatMessage("hc-header-external-users")}
                required={false}
                requiredForEnabling={true}
              />
            </Item>
          </GroupItem>
          <GroupItem colCount={2} colSpan={9}>
            <SimpleItem
              dataField="contract"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                maxLength: 100,
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    contract: e.value
                  }))
                }
              }}
            >
              <Label
                showColon={false}
                text={`${formatMessage("hc-field-contract")} ■`}
              />
              {rule("contract")}
            </SimpleItem>
            <SimpleItem
              dataField="contractType"
              editorType="dxLookup"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                dataSource: [
                  {
                    display: formatMessage("hc-placeholder-contract-type"),
                    value: null
                  },
                  ...contractTypes.map((contractType) => {
                    return { display: contractType, value: contractType }
                  })
                ],
                displayExpr: "display",
                valueExpr: "value",
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    contractType: e.value
                  }))
                },
                value: user.contractType,
                placeholder: formatMessage("hc-placeholder-contract-type"),
                dropDownOptions: {
                  title: formatMessage("hc-field-contract-types"),
                  closeOnOutsideClick: true
                },
                searchEnabled: false,
                showCancelButton: false
              }}
            >
              <Label
                showColon={false}
                text={formatMessage("hc-field-contract-type")}
              />
              {rule("contractType")}
            </SimpleItem>
            <SimpleItem
              dataField="skills"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                maxLength: 1024,
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    skills: e.value
                  }))
                }
              }}
            >
              <Label showColon={false} text={formatMessage("hc-field-skills")} />
              {rule("skills")}
            </SimpleItem>
            <SimpleItem
              dataField="contractEnd"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    contractEnd: e.value
                  }))
                }
              }}
              editorType="dxDateBox"
            >
              <Label
                showColon={false}
                text={`${formatMessage("hc-field-contract-end")} ■`}
              />
              {rule("contractEnd")}
            </SimpleItem>
            <SimpleItem
              dataField="orderEnd"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    orderEnd: e.value
                  }))
                }
              }}
              editorType="dxDateBox"
            >
              <Label
                showColon={false}
                text={`${formatMessage("hc-field-order-end")} ■`}
              />
              {rule("orderEnd")}
            </SimpleItem>
            <SimpleItem
              dataField="xTechAccountable"
              editorType="dxLookup"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                dataSource: [
                  {
                    name: formatMessage("hc-placeholder-xtech-accountable"),
                    id: null
                  },
                  ...userNames
                ],
                valueExpr: "id",
                displayExpr: "name",
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    xTechAccountable: e.value
                  }))
                },
                value: user.xTechAccountable,
                placeholder: formatMessage("hc-placehodler-xtech-accountable"),
                dropDownOptions: {
                  title: formatMessage("hc-field-xtech-accountable"),
                  closeOnOutsideClick: true
                },
                searchEnabled: true,
                showCancelButton: false
              }}
            >
              <Label
                showColon={false}
                text={`${formatMessage("hc-field-xtech-accountable")} ■`}
              />
              {rule("xTechAccountable")}
            </SimpleItem>
            <SimpleItem
              dataField="supplier"
              editorType="dxLookup"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                dataSource: [
                  {
                    display: formatMessage("hc-placeholder-supplier"),
                    value: null
                  },
                  ...suppliers.map((role) => {
                    return { display: role, value: role }
                  })
                ],
                displayExpr: "display",
                valueExpr: "value",
                onValueChanged: (e) => {
                  setUser((prevUser) => ({ ...prevUser, supplier: e.value }))
                },
                value: user.supplier,
                placeholder: formatMessage("hc-placeholder-supplier"),
                dropDownOptions: {
                  title: formatMessage("hc-field-suppliers"),
                  closeOnOutsideClick: true
                },
                searchEnabled: true,
                showCancelButton: false
              }}
            >
              <Label
                showColon={false}
                text={`${formatMessage("hc-field-supplier")} ■`}
              />
              {rule("supplier")}
            </SimpleItem>
            <SimpleItem
              dataField="equivalentBipLevel"
              editorType="dxLookup"
              editorOptions={{
                readOnly: !user.currentLevel.isExternal,
                dataSource: [
                  {
                    displayName: formatMessage(
                      "hc-placeholder-equivalent-bip-level"
                    ),
                    code: null
                  },
                  ...levels
                    .filter((l) => {
                      return !l.isExternal
                    })
                    .map((l) => {
                      return {
                        displayName: l.name + " - " + l.code,
                        code: l.code
                      }
                    })
                ],
                displayExpr: "displayName",
                valueExpr: "code",
                onValueChanged: (e) => {
                  setUser((prevUser) => ({
                    ...prevUser,
                    equivalentBipLevel: e.value
                  }))
                },
                placeholder: formatMessage("hc-placeholder-equivalent-bip-level"),
                dropDownOptions: {
                  title: formatMessage("hc-field-bip-levels"),
                  closeOnOutsideClick: true
                },
                searchEnabled: true,
                showCancelButton: false
              }}
            >
              <Label
                showColon={false}
                text={formatMessage("hc-field-equivalent-bip-level")}
              />
              {rule("equivalentBipLevel")}
            </SimpleItem>
          </GroupItem>
        </GroupItem>
      </Form>
    )
  }

  return (
    <Fragment>
      <div className="form-base" style={container}>
        <Form
          ref={formRef}
          labelLocation="top"
          formData={user}
          validationGroup="createUserForm"
        >
          {renderBasicInfoForm()}
          {renderLevelsForm()}
          {renderOrganizationalForm()}
        </Form>
        {renderExternalUserForm()}
      </div>
    </Fragment>
  )
}
