import { formatMessage } from "devextreme/localization"
import { getErrorByField } from "../../Headcount/Main/Utils/ErrorHandling"
import { useSelectAdbs } from "../hooks/selectHooks/useSelectAdbs"
import { useSelectCustomer } from "../hooks/selectHooks/useSelectCustomer"
import { useSelectFinalCustomer } from "../hooks/selectHooks/useSelectFinalCustomer"
import { useSelectStatuses } from "../hooks/selectHooks/useSelectStatuses"
import { useSelectSubType } from "../hooks/selectHooks/useSelectSubType"
import { useSelectTypes } from "../hooks/selectHooks/useSelectTypes"
import { useWbsTempCode } from "../hooks/useWbsTempCode"
import { useSelectWbsSuggestions } from "../hooks/selectHooks/useSelectWbsSuggestions"
import Checkbox from "./input/Checkbox"
import DropdownSelect from "./input/DropdownSelect"
import PercentageInput from "./input/PercentageInput"
import PriceInput from "./input/PriceInput"
import TextArea from "./input/TextArea"
import TextInput from "./input/TextInput"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAppAbility } from "../../../../Context/ability"
import { subject } from "@casl/ability"

export default function WbsDetailsForm({
  wbs,
  onValueChanged,
  isCreating = false,
  errors,
  canManage = false
}) {
  const { typeOptions } = useSelectTypes()
  const { subTypeOptions } = useSelectSubType()
  const { statusOptions } = useSelectStatuses()
  const { adbOptions, isLoading: adbOptionsLoading } = useSelectAdbs()
  const { customerOptions, isLoading: customerOptionsLoading } = useSelectCustomer()
  const { finalCustomerOptions } = useSelectFinalCustomer()

  const [suggestionsAccountables, setSuggestionsAccountables] = useState([])
  const [suggestionsAreas, setSuggestionsAreas] = useState([])
  const [isAreaRequired, setIsAreaRequired] = useState(true)

  const {
    suggestionsOptions,
    isLoading: suggestionsOptionsLoading
  } = useSelectWbsSuggestions(canManage, isCreating ? undefined : wbs.area)

  const wbsTempCode = useWbsTempCode()

  const user = useSelector((state) => state.Auth.user)

  const { cannot } = useAppAbility()
  const supporterEditing =
    !isCreating &&
    (cannot("manage", subject("Wbs", wbs), "accountable") ||
      cannot("manage", subject("Wbs", wbs), "area"))

  // Legal Entity values. xTech is excluded as it is represented by the already existing Alias Code
  const config = useSelector((state) => state.Auth.config)
  const [legalEntityValues, setLegalEntityValues] = useState([])
  const [selectedLegalEntities, setSelectedLegalEntities] = useState([])

  useEffect(() => {
    const values = config.legalEntities.sort()
    const valuesParsed = values
      .filter((value) => value !== "xTech")
      .map((value) => ({ id: value, cell: value }))
    setLegalEntityValues(valuesParsed)
  }, [config])

  // Check if Wbs has external support
  const [hasExternalSupport, setHasExternalSupport] = useState(false)

  /**
   * When supporters selects Area, set 'hasExternalSupport' to true if he is external supporter
   */
  const [externalSupporterCreating, setExternalSupporterCreating] = useState(false)
  useEffect(() => {
    if (wbs.area && suggestionsOptions?.areas) {
      const selectedArea = suggestionsOptions?.areas.filter(
        (area) => area.id === wbs.area
      )[0]
      if (selectedArea?.externalSupporters?.includes(user._id)) {
        onValueChanged("hasExternalSupport", true)
        setExternalSupporterCreating(true)
      } else {
        onValueChanged("hasExternalSupport", false)
        setExternalSupporterCreating(false)
      }
    }
  }, [suggestionsOptions.areas, wbs.area])

  /**
   * On Mount, set Accountables
   */
  useEffect(() => {
    if (suggestionsOptions?.accountables) {
      setSuggestionsAccountables([...suggestionsOptions.accountables])

      if (suggestionsAccountables.length <= 1) {
        onValueChanged("accountable", suggestionsAccountables[0]?.id || null)
      }
    }
  }, [suggestionsOptions.accountables])

  /**
   * When 'Accountable' is selected, suggested 'Areas' are filtered according to its owned/managed areas
   * Area is set to null to force choosing a valid area when available
   */
  useEffect(() => {
    if (suggestionsOptions && suggestionsOptions.areas) {
      let filteredAreas = []

      filteredAreas = suggestionsOptions.areas?.filter(
        (area) =>
          area.owner === wbs.accountable || area.deputies?.includes(wbs.accountable)
      )

      setIsAreaRequired(filteredAreas.length > 0)
      setSuggestionsAreas(filteredAreas)

      if (filteredAreas && filteredAreas.length === 1) {
        onValueChanged("area", filteredAreas[0].id)
      } else if (filteredAreas && filteredAreas.length > 1) {
        if (filteredAreas.some((area) => area.id === wbs.area)) {
          onValueChanged("area", wbs.area)
        } else {
          onValueChanged("area", null)
        }
      }
    }
  }, [
    wbs.accountable,
    suggestionsOptions.areas,
    user.internalSupportedAreas,
    user.externalSupportedAreas
  ])

  const isChargeable = () => wbs.type === "1"

  return (
    <div className="d-flex flex-column">
      <h4 className="mb-4">{!wbs.code || wbs.code === "" ? "-" : wbs.code}</h4>
      <div className="w-100 d-flex flex-row align-items-end mb-4">
        <TextInput
          disabled={wbs.isFinal || wbs.isTempPlaced}
          readOnly={!canManage}
          label={formatMessage("jw-Code") + " *"}
          value={wbs.isTempPlaced ? wbsTempCode : wbs.code}
          className="w-25"
          onChange={(event) => onValueChanged("code", event.target.value)}
          error={getErrorByField(errors, "code")}
          maxLength={15}
        />
        <TextInput
          disabled={wbs.isFinal || wbs.isTempPlaced}
          readOnly={!canManage}
          label={formatMessage("jw-AliasCode")}
          value={wbs.aliasCode}
          className="w-25 ml-3"
          onChange={(event) => onValueChanged("aliasCode", event.target.value)}
          error={getErrorByField(errors, "aliasCode")}
          maxLength={15}
        />
        <div
          className="pl-3 d-flex flex-row justify-content-center align-items-center"
          style={{ marginBottom: ".3rem", gap: "1rem" }}
        >
          <Checkbox
            readOnly={!canManage}
            label={formatMessage("jw-Active")}
            value={wbs.isActive}
            onChange={(event) => onValueChanged("isActive", event.target.checked)}
            error={getErrorByField(errors, "isActive")}
          />
          <Checkbox
            disabled={!wbs?.code || wbs?.code?.trim() === ""}
            readOnly={!canManage}
            label={formatMessage("jw-Final")}
            value={wbs.isFinal}
            onChange={(event) => {
              onValueChanged("isFinal", event.target.checked)
            }}
            error={getErrorByField(errors, "isFinal")}
          />
          <Checkbox
            disabled={!wbs?.code || wbs?.code?.trim() === ""}
            readOnly={!canManage}
            label={formatMessage("jw-TMP")}
            value={wbs.isTempPlaced}
            onChange={(event) => {
              onValueChanged("isTempPlaced", event.target.checked)
            }}
            error={getErrorByField(errors, "isTempPlaced")}
          />
          <Checkbox
            readOnly={!canManage || externalSupporterCreating}
            label={formatMessage("jw-External-Support")}
            value={wbs.hasExternalSupport}
            onChange={(event) =>
              onValueChanged("hasExternalSupport", event.target.checked)
            }
            error={getErrorByField(errors, "isActive")}
          />
        </div>
      </div>

      <div className="d-flex mb-4">
        {legalEntityValues.map((legalEntity) => (
          <TextInput
            disabled={wbs.isFinal || wbs.isTempPlaced}
            readOnly={!canManage}
            label={legalEntity.cell + " " + formatMessage("jw-AliasCode")}
            value={wbs.legalEntitiesCodes?.[legalEntity.id] || ''}
            className="w-25 mr-3"
            onChange={(event) => onValueChanged("legalEntitiesCodes", {...wbs.legalEntitiesCodes, [legalEntity.id]: event.target.value == "" ? null : event.target.value})}
            error={getErrorByField(errors, "legalEntitiesCodes")}
            maxLength={100}
          />  
        ))}
      </div>

      <div className="mb-4">
        <TextArea
          readOnly={!canManage}
          value={wbs.description}
          label={formatMessage("jw-Description") + " *"}
          placeholder={formatMessage("jw-Insert description")}
          className=""
          onChange={(event) => onValueChanged("description", event.target.value)}
          error={getErrorByField(errors, "description")}
        />
      </div>
      <div className="d-flex flex-row mb-4">
        <DropdownSelect
          readOnly={!isCreating}
          options={typeOptions}
          label={formatMessage("jw-Type") + " *"}
          className="w-25"
          value={wbs.type}
          onSelected={(option) => {
            onValueChanged("type", option?.id || null)
          }}
          error={getErrorByField(errors, "type")}
        />
        {isChargeable() ? (
          <DropdownSelect
            readOnly={!canManage}
            options={subTypeOptions}
            value={wbs.subType}
            label={formatMessage("jw-Sub type") + " *"}
            className="w-25 pl-3"
            onSelected={(option) => onValueChanged("subType", option?.id || null)}
            error={getErrorByField(errors, "subType")}
          />
        ) : (
          ""
        )}
        <DropdownSelect
          readOnly={!canManage}
          options={statusOptions}
          value={wbs.status}
          label={formatMessage("jw-Status") + " *"}
          className="w-25 pl-3"
          onSelected={(option) => onValueChanged("status", option?.id || null)}
          error={getErrorByField(errors, "status")}
        />
      </div>
      {isChargeable() ? (
        <>
          <div className="d-flex flex-row mb-4">
            <PriceInput
              readOnly={!canManage}
              className="w-25"
              label={formatMessage("jw-Contract value") + " *"}
              value={wbs.contractValue}
              onChange={(val) => {
                onValueChanged("contractValue", val)
              }}
              error={getErrorByField(errors, "contractValue")}
            />
            <PriceInput
              readOnly={!canManage}
              className="w-25 pl-3"
              label={formatMessage("jw-STD total costs") + " *"}
              value={wbs.standardCostsTotal}
              onChange={(val) => onValueChanged("standardCostsTotal", val)}
              error={getErrorByField(errors, "standardCostsTotal")}
            />
            <PriceInput
              readOnly={!canManage}
              className="w-25 pl-3"
              label={formatMessage("jw-Exp & other costs")}
              value={wbs.otherCosts}
              onChange={(val) => onValueChanged("otherCosts", val)}
              error={getErrorByField(errors, "otherCosts")}
            />
          </div>
          {!isCreating ? (
            <>
              <div className="d-flex flex-row mb-4">
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25"
                  label={formatMessage("jw-Margin")}
                  value={wbs.targetMargin}
                  onChange={(val) => onValueChanged("targetMargin", val)}
                  error={getErrorByField(errors, "targetMargin")}
                />
                <PercentageInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-Margin %")}
                  value={wbs.targetMarginPerc}
                  onChange={(event) =>
                    onValueChanged(
                      "targetMarginPerc",
                      parseFloat(event.target.value)
                    )
                  }
                  error={getErrorByField(errors, "targetMarginPerc")}
                />
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-Managerial")}
                  value={wbs.revenueXtechManagerial}
                  onChange={(val) => onValueChanged("revenueXtechManagerial", val)}
                  error={getErrorByField(errors, "revenueXtechManagerial")}
                />
              </div>
              <div className="d-flex flex-row mb-4">
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25"
                  label={formatMessage("jw-Margin xTech")}
                  value={wbs.xTechMargin}
                  onChange={(val) => onValueChanged("xTechMargin", val)}
                  error={getErrorByField(errors, "xTechMargin")}
                />
                <PriceInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-STD cost xTech")}
                  value={wbs.standardCostsXtech}
                  onChange={(val) => onValueChanged("standardCostsXtech", val)}
                  error={getErrorByField(errors, "standardCostsXtech")}
                />
                <PercentageInput
                  readOnly={true || !canManage}
                  className="w-25 pl-3"
                  label={formatMessage("jw-STD effort xTech")}
                  value={wbs.xTechEffortPerc}
                  onChange={(event) =>
                    onValueChanged("xTechEffortPerc", parseFloat(event.target.value))
                  }
                  error={getErrorByField(errors, "xTechEffortPerc")}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <div className="d-flex flex-row mb-4">
        <DropdownSelect
          disabled={adbOptionsLoading}
          readOnly={!canManage}
          options={adbOptions}
          value={wbs.adb}
          label={formatMessage("jw-Adb") + " *"}
          className="w-25"
          onSelected={(option) => onValueChanged("adb", option?.id || null)}
          error={getErrorByField(errors, "adb")}
        />
        <DropdownSelect
          disabled={suggestionsOptionsLoading}
          readOnly={
            !canManage || suggestionsAccountables.length <= 1 || supporterEditing
          }
          options={suggestionsAccountables || []}
          value={wbs.accountable}
          label={formatMessage("jw-Accountable") + " *"}
          className="w-25 pl-3"
          onSelected={(option) => {
            onValueChanged("accountable", option?.id || null)
          }}
          error={getErrorByField(errors, "accountable")}
        />

        <DropdownSelect
          disabled={suggestionsOptionsLoading}
          readOnly={!canManage || suggestionsAreas?.length <= 1 || supporterEditing}
          options={suggestionsAreas || []}
          value={wbs.area}
          label={
            isAreaRequired
              ? formatMessage("jw-Area") + " *"
              : formatMessage("jw-Area")
          }
          className="w-25 pl-3"
          onSelected={(option) => onValueChanged("area", option?.id || null)}
          error={getErrorByField(errors, "area")}
        />
        {!isCreating && isChargeable() ? (
          <PriceInput
            readOnly={true || !canManage}
            className="w-25 pl-3"
            label={formatMessage("jw-RFP")}
            value={wbs.rfpXTech}
            onChange={(event) => onValueChanged("rfpXTech", event.target.value)}
            error={getErrorByField(errors, "rfpXTech")}
          />
        ) : (
          ""
        )}
      </div>
      {isChargeable() ? (
        <div className="d-flex flex-row mb-4">
          <DropdownSelect
            disabled={customerOptionsLoading}
            readOnly={!canManage}
            options={customerOptions}
            value={wbs.billToCustomer}
            label={formatMessage("jw-Bill to Customer") + " *"}
            className="w-25"
            onSelected={(option) =>
              onValueChanged("billToCustomer", option?.id || null)
            }
            error={getErrorByField(errors, "billToCustomer")}
          />
          <DropdownSelect
            readOnly={!canManage}
            options={finalCustomerOptions}
            value={wbs.finalCustomer}
            label={formatMessage("jw-Final customer") + " *"}
            className="w-25 pl-3"
            onSelected={(option) =>
              onValueChanged("finalCustomer", option?.id || null)
            }
            error={getErrorByField(errors, "finalCustomer")}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
