import { useQuery } from "@tanstack/react-query"
import { getJobFilters } from "../../../../../services"
import { parseBipUserData } from "../../utils/parseBipUserData"
import { useEffect, useState } from "react"

export function useSelectJobFilters() {
  const jobOwners = true
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["GET", "user", { jobOwners }],
    async () => {
      return await getJobFilters().then((data) => {
        const jobOwners = data.data[0].jobOwners.map((jobOwnerUser) => parseBipUserData(jobOwnerUser))
        const deliveryManagers = data.data[0].deliveryManagers.map((deliveryManagerUser) => parseBipUserData(deliveryManagerUser))
        return { jobOwners, deliveryManagers }
      })
    },
    { staleTime: 1000 * 60 * 2 }
  )

  const parseJobOwnerUserId = (jobOwnerUserId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return (
        data.jobOwners.find((jobOwnerUser) => jobOwnerUser.id === jobOwnerUserId) || "-"
    )
  }

  const parseDeliveryManagerUserId = (deliveryManagerUserId) => {
    if (isError) {
      return "error"
    }

    if (isLoading) {
      return "-"
    }

    return (
        data.deliveryManagers.find((deliveryManagerUser) => deliveryManagerUser.id === deliveryManagerUserId) || "-"
    )
  }

  const getJobOwnerUsersAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    
    return data.jobOwners.sort((a, b) => a.fullName.localeCompare(b.fullName)).map((u) => ({ id: u.id, cell: u.fullName }))
  }

  const getDeliveryManagerUsersAsOptions = () => {
    if (!isSuccess) {
      return []
    }
    
    return data.deliveryManagers.sort((a, b) => a.fullName.localeCompare(b.fullName)).map((u) => ({ id: u.id, cell: u.fullName }))
  }

  const [jobOwnerUserOptions, setJobOwnerUserOptions] = useState(getJobOwnerUsersAsOptions())
  const [deliveryManagerUserOptions, setDeliveryManagerUserOptions] = useState(getDeliveryManagerUsersAsOptions())

  useEffect(() => {
    setJobOwnerUserOptions(getJobOwnerUsersAsOptions())
    setDeliveryManagerUserOptions(getDeliveryManagerUsersAsOptions())
  }, [data])

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    parseJobOwnerUserId,
    jobOwnerUserOptions,
    parseDeliveryManagerUserId,
    deliveryManagerUserOptions,
  }
}
